//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from '@/service/miceService'
	export default {
		data() {
			return {
				dataList: [],
				pageIndex: '1',
				pageSize: '20',
				allLoaded: false,
				topHeight: 0,
				bottomHeight: 0,
				searchInput: '',
				curHospital:''
			}
		},
		watch:{
			searchInput(){
				this.$delaySearch(()=>{
					this.pageIndex = 1;
        			this.loadData();
				}, 200)	
			}
		},
		created() {
			this.curHospital = sessionStorage.getItem("hospital");
			this.loadData()
		},
		methods: {
			//选择医院
			selectHospital(item, index){
				this.curHospital = item.name;
				sessionStorage.setItem("hospital", item.name);
        		sessionStorage.setItem("hospitalId", item.rowId);
        		let routerPath = '';
        		switch(sessionStorage.getItem('itemType')){
        			case '5':
        				routerPath = '/myShops';
        				break;
        			case '7':
        				routerPath = '/myTakeaway';
        				break;
        		}
        		this.$router.push({
        			path: routerPath
        		})
			},
			async loadData(pageIndex) {
				this.allLoaded = false;
				this.pageIndex = pageIndex || this.pageIndex; //更新自iEventScroll组件
				if(!sessionStorage.getItem("cityId")) {
					return
				}
				let params = {
					cityId: sessionStorage.getItem("cityId"),
					pageSize: this.pageSize,
					pageIndex: this.pageIndex,
					searchInput: this.searchInput.replace(/\s/g, "")
				}
				let response = await miceService.getHospitalList(params)
				if(this.pageIndex == 1){
					this.dataList = [];
				}
				this.dataList = this.dataList.concat(response.content.rows);
				if(this.dataList.length == response.content.total){
					this.allLoaded = true;
				}
			},
			goBack() {
				this.$router.push({
					path: 'chooseType'
				})
			}
		}
	}
